import {patch} from '@rails/request.js';

$(document).on("DOMContentLoaded", () => {
  async function updateAssignee() {
    const $select = $(".assignee-selection").find("select"),
      newAssignee = $select.val(),
      currentAssignee = $select.data("currentAssignee"),
      body = {ajax: "very", assigned_to_administrator_id: newAssignee};

    $select.prop("disabled", true);
    $select.trigger('blur');

    const response = await patch(location.pathname, {body}).catch(() => ({ok: false}));
    if (!response.ok) {
      $select.val(currentAssignee);
    }

    $select.prop("disabled", false);
  }

  if ($(".organisation-application-view").length) {
    $(".organisation-application-view").on("change .assignee-selection select", updateAssignee);
  }
});
