import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.icons = [
      " ",
      this.element.dataset.icon1,
      this.element.dataset.icon2,
      this.element.dataset.icon3
    ];

    this.dirty = false;

    // We'd normally use Stimulus for this but ujs is preventing it from running.
    document.querySelector('form').addEventListener('submit', e => this.submit(e));

    this.beforeunloadBound = this.beforeunload.bind(this);
    window.addEventListener('beforeunload', this.beforeunloadBound);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.beforeunloadBound);
  }

  click(event) {
    event.preventDefault();

    const link = event.currentTarget;
    const value = (parseInt(link.dataset.value || '0') + 1) % 4;

    if (value) {
      link.dataset.value = value;
    } else {
      delete link.dataset.value;
    }

    link.querySelector('span').innerText = this.icons[value];
    link.querySelector('input').value = value;

    this.dirty = true;
  }

  submit() {
    this.dirty = false;
  }

  beforeunload(event) {
    if (this.dirty) {
      event.preventDefault();
      return event.returnValue = "You've got unsaved changes. Sure you want to leave this page?";
    }
  }
}
