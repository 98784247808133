import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dirty"
export default class extends Controller {
  connect() {
    this.dirty = false;

    this.element.addEventListener('submit', () => {this.dirty = false;});
    this.element.addEventListener('input', () => {this.dirty = true;});

    this.beforeunloadBound = this.beforeunload.bind(this);
    window.addEventListener('beforeunload', this.beforeunloadBound);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.beforeunloadBound);
  }

  beforeunload(event) {
    if (this.dirty) {
      event.preventDefault();
      return event.returnValue = "You've got unsaved changes. Sure you want to leave this page?";
    }
  }
}
