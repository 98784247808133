import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['source', 'button'];

  connect() {
    if (!navigator.clipboard) {
      this.buttonTarget.innerHTML = '';
    }
  }

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.buttonTarget.innerHTML = 'check_circle';
  }
}
