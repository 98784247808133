import { debounce } from 'throttle-debounce';
import pageRunner from './page_runner';
import { Uploader } from './uploading';

$(document).on("DOMContentLoaded", () => {
  let lastParamsSent = null;

  function pushChangedCriteria() {
    let form      = $("main.events form"),
        params    = form.serialize(),
        ajaxURL   = location.pathname + "?ajax=very&" + params,
        prettyURL = location.pathname + "?" + params;

    if (params === lastParamsSent) { return; }
    lastParamsSent = params;

    $(".search-results").addClass("loading");

    let request = $.get(ajaxURL);

    request
      .always(() => $(".search-results").removeClass("loading"))
      .done(data => $("#search-results-container").html(data))
      .fail(()   => lastParamsSent = null);

    history.pushState && request.done(() => history.pushState({url: prettyURL}, null, prettyURL));
  }

  if ($("main.events").length) {
    $("main.events")
      .on("change input propertychange", "input", debounce(250, pushChangedCriteria));
  }
});

pageRunner.registerMainClass('event-view', page => {
  $("main.event-view section.stats-pane.linked").on("click", () => {
    location.href = location.pathname + "/attendances";
  });

  if ($("a.upload-link").length) {
    new Uploader(page).start();
  }
});

function setSurveyUrlTemplateVisibility() {
  const checked = $("#survey_enabled").is(":checked");
  $("#survey-url-template-label")[checked ? "show" : "hide"]();
}

pageRunner.registerSelector('main.new-event, main.edit-event', () => {
  setSurveyUrlTemplateVisibility();
  $("#survey_enabled").on("input", setSurveyUrlTemplateVisibility);
});
