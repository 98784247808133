import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  show() {
    this.element.classList.remove('hidden');
  }

  cancel(event) {
    event.preventDefault();
    this.element.classList.add('hidden');
  }
}
