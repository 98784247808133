import pageRunner from './page_runner';
import { Uploader } from './uploading';

pageRunner.registerSelector(".employer-edit", page => {
  new Uploader(page).start();

  let confirmField = $(".confirm-current-password");

  $("#employer_password, #employer_email").on("change, input", e => {
    confirmField.addClass("changed");
  });
});
