import {Controller} from "@hotwired/stimulus"
import {post, destroy} from '@rails/request.js';
import dialogConfirm from "../dialogs";

// Connects to data-controller="choice-category-selector"
export default class extends Controller {
  like(event) {
    event.preventDefault();
    this.handleResponse(post(event.params.url));
  }

  async remove(event) {
    event.preventDefault();

    if (event.params.warning) {
      const answer = await dialogConfirm(event.params.warning);
      if (!answer) { return; }
    }

    this.handleResponse(destroy(event.params.url));
  }

  async handleResponse(promise) {
    const response = await promise.catch(error => {
      console.error('Choice operation error:', error);
      return {ok: false};
    });

    if (response.ok) {
      const html = await response.html;

      if (this.element.parentNode) {
        this.element.outerHTML = html;
      }
    }
    else {
      this.element.classList.remove('error');
      void this.element.offsetWidth;
      this.element.classList.add('error');
    }
  }
}
