import pageRunner from './page_runner';
import PDFViewer from './pdf_viewer';
import { Uploader } from './uploading';

pageRunner.registerSelector(".student-edit-section", page => {
  // This block runs for all student edit sections

  let handlerLoaded = false;

  function beforeunload(event) {
    if (page.hasClass("dirty")) {
      event.preventDefault();
      return event.returnValue = "You've got unsaved changes. Sure you want to leave this page?";
    }
  }

  $("main input, main textarea, main select").not(":input[type=file]").on("input", () => {
    if (!handlerLoaded) {
      handlerLoaded = true;
      window.addEventListener('beforeunload', beforeunload);
    }

    page.addClass("dirty");
    $(".publish-profile").hide();
  });

  $("main form").on("submit", () => page.removeClass("dirty"));
});

pageRunner.registerMainClass('student-section-situation', page => {
  $(".situation input").on('click', e => {
    const situation = $(e.currentTarget).val();

    $(".current-year-and-contact").removeClass("studying graduated discontinued inapplicable").addClass(situation);
  });


  page.on('input', 'input[name="student_visa_status[visa_status_id]"]', e => {
    const showExpiry = e.currentTarget.dataset.expires === "true";
    $("#visa-expiry").toggle(showExpiry);
  });

  function setEnabledStatusOfYearOfStudy() {
    const totalYears = $(".qualification-total-years input:checked").val();

    for (let year = 1; year <= 9; year++) {
      const disabled = year > totalYears;
      const $el = $(`.year-of-study input[value=${year}]`).prop("disabled", disabled);

      if (disabled) { $el.prop("checked", false); }
    }
  }

  setEnabledStatusOfYearOfStudy();
  $(".qualification-total-years input").on('input', setEnabledStatusOfYearOfStudy);
});

pageRunner.registerMainClass('student-section-requirements', () => {
  $('.job-types input[type=checkbox], .region').on('click', () => {
    let jobTypeChecked = $('.job-types input:checked');
    let regionChecked = $('.region input:checked');

    $(".state-change").toggleClass("hidden", !!regionChecked.length && !!jobTypeChecked.length)
  });

  $('.job-type-selector').on('click', e => {
    let interested = $(e.currentTarget).is(":checked");
    let programmeID = $(e.currentTarget).data("programme-id");
    let jobTypeID = $(e.currentTarget).data("job-type-id");
    let $checkedProgrammes = $(`.job-types input:checked[data-programme-id=${programmeID}]`);
    let $programmeInfo = $(`li.programme-info[data-programme-id=${programmeID}]`);

    $programmeInfo.toggleClass("hidden", !$checkedProgrammes.length);
    $(".job-eligibility").toggleClass("hidden", !$("li.programme-info").not(".hidden").length)

    $(`#availability-time-${jobTypeID}`).toggleClass("hidden", !interested);
  });
});

pageRunner.registerMainClass('student-section-photo', page => {
  new Uploader(page).start();
});

pageRunner.registerMainClass('student-section-files', page => {
  new PDFViewer(page).start();
  new Uploader(page).start();
});
