import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog-opener"
export default class extends Controller {
  open(event) {
    event.preventDefault();

    const dialog = document.querySelector('section.dialog-screen');
    this.application.getControllerForElementAndIdentifier(dialog, 'dialog').show();
  }
}
