class CategorySelector {
  constructor() {
    this.$openedSelector = null;
    this.$openedSelectorList = null;
  }

  install(container) {
    container
      .on("click", ".category-selector .target", this.categorySelectorTargetClicked.bind(this))
      .on("click", ".category-selector .check", this.categorySelectorCheckClicked.bind(this));

    return this;
  }

  categorySelectorTargetClicked(e) {
    e.stopPropagation();

    const $categorySelector = $(e.target).closest('.category-selector');

    this.closeCategorySelector();
    this.openCategorySelector($categorySelector);

    $(document).one('click.categorySelector', this.closeCategorySelector.bind(this));
  }

  async categorySelectorCheckClicked(e) {
    const $categorySelector = $(e.target).closest('.category-selector');
    const $job = $categorySelector.closest('.job');
    const jobId = $job.data('job-id');

    if ($categorySelector.hasClass("rejected")) {
      this.categorySelectorTargetClicked(e);
      return;
    }

    $categorySelector.toggleClass('selected');
    const selected = $categorySelector.hasClass('selected');

    const data = {jobId, $categorySelector, deselectOnError: selected};
    if (selected) {
      data.categoryId = $categorySelector.data('default-id');
      this.callUpdateAPI(data);
    }
    else {
      const {ok, data: newData} = await this.confirmRejection(data);
      const result = ok && await this.callUpdateAPI(newData);
      if (!result) {
        $categorySelector.addClass('selected');
      }
    }
  }

  confirmRejection(_data) {
    return Promise.resolve({ok: false});
  }

  async listCategoryClicked(e) {
    const $currentTarget = $(e.currentTarget);
    const categoryId = $currentTarget.data('category');
    const reject = !!$currentTarget.data('reject');
    const jobId = $currentTarget.closest('ul').data('jobId');
    const showDialog = reject && !this.$openedSelector.hasClass("rejected");
    const data = {categoryId, jobId, reject, $categorySelector: this.$openedSelector};

    this.closeCategorySelector();

    if (showDialog) {
      const {ok, data: newData} = await this.confirmRejection(data);
      if (ok) {
        this.callUpdateAPI(newData);
      }
    } else {
      this.callUpdateAPI(data);
    }
  }

  openCategorySelector($categorySelector) {
    const $job = $categorySelector.closest('.job');
    const jobId = $job.data('job-id');
    const listId = $job.data('category-selector-list');
    const $list = $(`#${listId}`);

    this.$openedSelector = $categorySelector;
    this.$openedSelectorList = $list
      .clone()
      .css({
        display: 'block',
        width: $categorySelector.outerWidth() + "px",
        top: $categorySelector.position().top,
        left: $categorySelector.position().left,
      })
      .data('jobId', jobId)
      .insertAfter($categorySelector)
      .on('click', 'li', this.listCategoryClicked.bind(this));
  }

  closeCategorySelector() {
    if (this.$openedSelectorList) {
      this.$openedSelectorList.remove();
      this.$openedSelector = null;
      this.$openedSelectorList = null;

      $(document).off('click.categorySelector');
    }
  }

  async callUpdateAPI({jobId, categoryId, reject, $categorySelector, deselectOnError, note}) {
    const data = {job_id: jobId, category_id: categoryId, note, reject};

    $categorySelector.addClass('saving');

    const response = await this.update(data).catch(() => ({ok: false}));
    if (response.ok) {
      const html = await response.html;
      $categorySelector.replaceWith(html);
    } else {
      if (deselectOnError) {
        $categorySelector.removeClass('selected');
      }

      const currentCategoryName = $categorySelector.find(".target").text();

      let message = "Error updating, please try again";
      try { message = JSON.parse(await response.json).error; }
      catch (_error) {}

      if (message) {
        $categorySelector
          .addClass("error")
          .find(".target").text(message);

        setTimeout(function() {
          $categorySelector
            .removeClass("error")
            .find(".target").text(currentCategoryName);
        }, 1500);
      }
    }

    $categorySelector.removeClass('saving');
    return response.ok;
  }

  update() {
    return Promise.reject();
  }
}

export {CategorySelector};
