import { debounce } from 'throttle-debounce';
import dialogConfirm from './dialogs'
import {post} from '@rails/request.js';

$(document).on("DOMContentLoaded", function() {
  let lastURLRequested = null;

  function pushChangedCriteria() {
    const
      form         = $(".jobs-container input:not(.excluded-from-criteria)"),
      params       = form.serialize(),
      organisation = $("input[type=hidden]#organisation"),
      mode         = $("input[name=mode]:checked");

    const organisationSpecified = organisation.val() && mode.val() !== 'all';
    const baseURL = organisationSpecified ? `/organisations/${organisation.val()}/jobs` : '/jobs';

    const
      ajaxURL   = `${baseURL}?ajax=very&${params}`,
      prettyURL = `${baseURL}?${params}`;

    if (prettyURL === lastURLRequested) { return; }
    lastURLRequested = prettyURL;

    $(".search-results").addClass("loading");

    let request = $.get(ajaxURL);

    request
      .always(() => $(".search-results").removeClass("loading"))
      .done(data => $("#jobs-search-results").html(data))
      .fail(()   => lastURLRequested = null);

    history.pushState && request.done(() => history.pushState({url: prettyURL}, null, prettyURL));
  }

  if ($(".jobs-container").length) {
    $(".jobs-container")
      .on("change input propertychange", "input:not(.excluded-from-criteria)", debounce(250, pushChangedCriteria))
      .on("click", ".clear-skills", () => {
        $(".skills-selection aside .skill-display-list").empty();
        $("section.skills-container section.skill-list input:checked").prop('checked', false);
        $("fieldset.skills").removeClass("none some").addClass("none");
        pushChangedCriteria();
    });
  }

  if ($("main.job-view").length) {
    $("a.open-state-dialog").on('click', e => {
      e.preventDefault();
      $('#state-dialog').removeClass('hidden');
    });

    $("#state-dialog button").on('click', () => $('#state-dialog').addClass('hidden'));
  }

  if ($(".job-edit, .job-new").length) {
    $(".skills-fields")
      .on("click", ".clear-skills", () => {
        $(".skills-selection .skill-display-list").empty();
        $("section.skills-container section.skill-list input:checked").prop('checked', false);
        $(".skills-fields").removeClass("none some").addClass("none");
    });
  }
});
