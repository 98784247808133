import {Controller} from "@hotwired/stimulus"
import {get, post, destroy} from "@rails/request.js";
import dialogConfirm from "../dialogs";

// Connects to data-controller="application-question"
export default class extends Controller {
  static targets = ['answers', 'type', 'required', 'typeName', 'markdown'];
  static values = {id: String, job: String, editing: Boolean};
  static outlets = ['application-form'];

  connect() {
    this.updateAnswersVisibility();
  }

  url() {
    return `/jobs/${this.jobValue}/application_questions/${this.idValue}`;
  }

  async edit() {
    if (this.applicationFormOutlet.editingValue) {return;}

    const response = await get(`${this.url()}/edit`);
    const html = await response.html;
    this.element.innerHTML = html;
    this.editingValue = true;
  }

  async submit(event) {
    event.preventDefault();
    const form = event.target;
    const response = await post(form.action, {body: new FormData(form)});
    const html = await response.html;
    this.element.innerHTML = html;

    const id = response.headers.get('x-id');
    if (id) {this.idValue = id;}

    this.editingValue = response.statusCode !== 200;
    this.updateAnswersVisibility();
  }

  async delete() {
    if (await dialogConfirm("Are you sure you want to delete this question?")) {
      const response = await destroy(this.url());
      if (response.statusCode === 200) {
        this.applicationFormOutlet.editingValue = false;
        this.element.remove();
      }
    }
  }

  async cancel() {
    if (!this.idValue) {
      this.applicationFormOutlet.editingValue = false;
      this.element.remove();
      return;
    }

    const response = await get(this.url());

    if (response.statusCode === 200) {
      const html = await response.html;
      this.element.innerHTML = html;
      this.editingValue = false;
    }
  }

  typeUpdate() {
    this.updateAnswersVisibility();
  }

  editingValueChanged(value) {
    this.applicationFormOutlet.editingValue = value;
    this.updateAnswersVisibility();
  }

  updateAnswersVisibility() {
    if (this.hasTypeTarget) {
      const value = this.typeTarget.value;
      this.answersTarget.style.display = value === 'choice' ? null : 'none';
      this.requiredTarget.style.display = value === 'none' ? 'none' : null;
      this.markdownTarget.style.display = value === 'none' ? null : 'none';
      for (const el of this.typeNameTargets) {
        el.innerText = value === 'none' ? 'Text' : 'Question';
      }
    }
  }
}
