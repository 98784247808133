export default class PDFViewer {
  constructor(page) {
    this.page = page;
  }

  start() {
    let closePDFViewer = () => {
      $(".pdf-view").remove();
      $("body").removeClass("noscroll").off("click", closePDFViewer);
      $(document).off("keydown");
    };

    $(this.page).on("click", "a.display-pdf-link", e => {
      if (e.ctrlKey || e.metaKey || e.shiftKey) { return; }
      if ($(document).width() < 800) { return; }

      e.preventDefault();
      e.stopPropagation();

      let url = e.currentTarget.href;

      let pdfView = $("<div>").addClass("pdf-view").appendTo($("body"));

      $("<iframe>").addClass("pdf-container").attr("src", url).appendTo(pdfView);

      setTimeout(() => {
        $("<div>").addClass("loading-message").html(`Loading... If your browser cannot view PDFs, <a href="${url}">download the PDF</a>.`).appendTo(pdfView);
      }, 1000);

      $("body").addClass("noscroll").on("click", closePDFViewer);

      $(document).on("keydown", e => {
        if (e.which === 27) { closePDFViewer(); }
      });
    });
  }
}
