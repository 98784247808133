function showConfirmRejectionDialog(studentName) {
  return new Promise(resolve => {
    function buttonClicked(e) {
      e.preventDefault();

      const action = $(e.currentTarget).data('action');

      if (action === 'reject') {
        const note = $('#personalised-rejection-note').val();
        resolve({ok: true, note});
      } else {
        resolve({ok: false});
      }

      $('#confirm-rejection-dialog').hide();
    }

    $("#confirm-rejection-dialog")
      .css('display', 'flex')
      .one("click", ".actions a", buttonClicked)
      .find('.student-name')
      .text(studentName);
  });
}

export default showConfirmRejectionDialog;
