import pageRunner from './page_runner';
import dragula from 'dragula';
import {put} from '@rails/request.js';

pageRunner.registerMainClass('prospect-categories-management', $container => {
  $container.on("click", ".edit-link", e => {
    let $el = $(e.currentTarget);

    $el.closest(".prospect-category").addClass("edit-mode");
  });

  $container.on("click", ".make-hidden, .make-visible", e => {
    setTimeout(() => $(e.currentTarget).closest("form")[0].submit(), 0);
  });

  let $categories = $(".prospect-categories");

  let drake = dragula($categories.toArray(), {
    isContainer: el => $(el).hasClass(".prospect-category"),
    moves:       (el, source, handle, sibling) => true,
    accepts:     (el, source, handle, sibling) => true,
    invalid:     (el, target) => false,
    direction: 'vertical',             // Y axis is considered when determining where an element would be dropped
    copy: false,                       // elements are moved by default, not copied
    copySortSource: false,             // elements in copy-source containers can be reordered
    revertOnSpill: true,               // spilling will put the element back where it was dragged from, if this is true
    removeOnSpill: false,              // spilling will `.remove` the element, if this is true
    mirrorContainer: $container[0],    // set the element that gets mirror elements appended
    ignoreInputTextSelection: true     // allows users to select input text, see details below
  });

  drake.on("drop", async (el, _target, _source, _sibling) => {
    let $el = $(el);
    let url = $el.data("url");
    let position = $el.index() + 1;

    $('.saving-indicator').show();

    const response = await put(url, {body: {prospect_category: {position}}}).catch(() => ({ok: false}));

    if (response.ok) {
      const data = await response.html;
      $(".prospect-categories").html(data);
    } else {
      location.href = location.href;
    }

    $('.saving-indicator').hide();
  });
});
