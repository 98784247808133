import { Controller } from "@hotwired/stimulus"

// USAGE:
//
// <div data-controller="prefixed-input">
//   <input>
//   <span>Prefix text</span>
// </div>
//
// Include data-action="resize@window->prefixed-input#layout" on the
// controller element if the prefixed text changes depending on the
// page width.

export default class extends Controller {
  connect() {
    const input = this.element.querySelector('input');
    if (input) {
      this.originalPadding = input.style.paddingLeft;
    }

    this.layout();
  }

  layout() {
    const span = this.element.querySelector('span');
    const input = this.element.querySelector('input');

    if (input && span && span.clientWidth) {
      const style = getComputedStyle(span);
      const width = span.clientWidth - parseFloat(style.paddingRight) + 1;

      input.style.paddingLeft = `${width}px`;
    }
    else {
      input.style.paddingLeft = this.originalPadding;
    }
  }
}
