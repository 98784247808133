import {FetchRequest, get} from '@rails/request.js';
import Croppie from 'croppie';
import 'croppie/croppie.css';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const MAX_PNG_SIZE = 250 * 1024;
const PREFERRED_MAX_JPEG_SIZE = 250 * 1024;
const UPLOAD_IMAGE_WIDTH = 800;
const DEFAULT_FILE_TYPE = 'jpeg';

class UploaderModal {
  constructor({uploadScreen, container, output}) {
    this.uploadScreen = uploadScreen;
    this.container = container;
    this.output = output || DEFAULT_FILE_TYPE;
    this.croppieImage = null;
  }

  show() {
    this.uploadScreen.css('display', 'flex');
    this.uploadScreen.find(".upload-container").hide();
    this.container.show();

    this.originalProfileImage = $(".profile-image-holder").html();
  }

  hide() {
    if ($(".profile-image-holder").length) {
      $(".profile-image-holder").html(this.originalProfileImage);
      $(".upload-text").show();
      $(".image-manipulate-text").hide();
    }

    this.container.find("form").each((_, el) => el.reset());

    this.uploadScreen.find(".upload-container").hide();
    this.uploadScreen.find(".is-uploading").hide();
    this.uploadScreen.find(".error-message").empty();
    this.uploadScreen.hide();
  }

  isProfileImage() {
    return this.container.attr('id') === 'profile-upload-container';
  }

  processProfileImage(file) {
    const reader = new FileReader();

    reader.onload = e => {
      const img = $("<img>").attr('src', e.target.result);

      img.on('error', () => {
        this.container.find(".error-message").text("Sorry, that file isn't a valid image file.  Try another one.");
        return;
      });

      img.on('load', () => {
        if (img[0].width < 300 || img[0].height < 300) {
          this.container.find(".error-message").text("Your image must have a width and height of at least 300 pixels, otherwise it'll be blurry.");
          return;
        }

        this.container.find(".error-message").empty();
        $(".profile-image-holder").empty().append(img);
        $(".upload-text").hide();
        $(".image-manipulate-text").show();

        const size = $('.profile-image-holder').width();
        const opts = {viewport: {width: size, height: size, type: 'square'}};

        this.croppieImage = new Croppie(img[0], opts);
      });
    };

    reader.readAsDataURL(file);
  }

  fileDropped(file) {
    if (!file) { return; }

    if (this.isProfileImage()) {
      this.processProfileImage(file);
    }
    else {
      this.upload(file);
    }
  }

  fileSelected(file) {
    if (!file) { return; }

    if (this.isProfileImage()) {
      this.processProfileImage(file);
    } else {
      this.container.find("form").submit();
    }
  }

  async uploadButtonClicked() {
    const size = {width: UPLOAD_IMAGE_WIDTH};

    if (this.output === "png") {
      const blob = await this.renderAsPNG(size);

      if (blob) {
        this.upload(blob, 'image.png');
        return;
      }
    }

    const blob = await this.renderAsJPEG(size);
    this.upload(blob, 'image.jpg');
  }

  log() {
    const args = ['[image uploader]', ...arguments];
    console.log.apply(console, args);
  }

  async renderAsPNG(size) {
    this.log('rendering PNG');
    const blob = await this.croppieImage.result({type: 'blob', size, format: 'png'});
    if (blob.size > MAX_PNG_SIZE) {
      this.log(blob.size, 'bytes is over the PNG threshold, falling back to JPEG');
    } else {
      this.log("PNG is", blob.size, "bytes, that works");
      return blob;
    }
  }

  async renderAsJPEG(size) {
    let blob = null;

    for (let quality = 0.95; quality >= 0.8 && (!blob || blob.size > PREFERRED_MAX_JPEG_SIZE); quality -= 0.04) {
      this.log('rendering JPEG with quality', quality);
      blob = await this.croppieImage.result({type: 'blob', size, format: 'jpeg', quality});
      this.log("JPEG is", blob.size, "bytes");
    }

    return blob;
  }

  async upload(blob, filename) {
    const form = this.container.find("form");
    const isUploading = this.uploadScreen.find(".is-uploading");
    const fileInput = form.find("input[type=file]");
    const data = new FormData(form.get(0));

    if (isUploading.is(":visible")) { return; }

    const fileSize = (blob && blob.size) || (fileInput[0].files && fileInput[0].files[0] && fileInput[0].files[0].size);

    if (typeof(fileSize) !== 'undefined' && fileSize > MAX_FILE_SIZE) {
      this.container.find(".error-message").text(`Your file is too big for us!  Try one that's under ${MAX_FILE_SIZE/1048576} MB.`);
      return;
    }

    if (blob) {
      data.append(fileInput.attr('name'), blob, filename);
    }

    isUploading.show();
    this.container.hide();

    const request = new FetchRequest(
      form.attr("method"),
      form.attr("action"),
      {body: data}
    );

    const response = await request.perform().catch(() => ({ok: false}));

    if (response.ok) {
      this.hide();

      const partial = this.container.data("partial");
      if (partial) {
        const profileContainer = $("." + this.container.data("dom-location")).addClass("loading");

        const response = await get(location.pathname + "?partial=" + partial).catch(() => ({ok: false}));
        if (response.ok) {
          profileContainer.html(await response.html);
        } else {
          profileContainer.text("Failed to load.");
        }

        profileContainer.removeClass("loading");
      }
      else {
        window.location.assign(location.toString());
      }
    } else {
      const text = (response.text && await response.text.catch(() => null)) || "unknown error";
      this.container.find(".error-message").text("Upload error: " + text);
      isUploading.hide();
      this.container.show();
    }
  }
}

class Uploader {
  constructor(page) {
    this.page = page;
    this.uploadScreen = $('#upload-screen');
    this.modal = null;
  }

  start() {
    this.installOpenModalLinkListener();
    this.installFileSelectedListener();
    this.installUploadProfileImageButtonListener();
    this.installFormSubmitListener();
    this.installCloseModalButtonListener();
    this.installEscapeKeyListener();
    this.installDragDropListeners();
  }

  installOpenModalLinkListener() {
    $(this.page).on("click", "a.upload-link", e => {
      e.preventDefault();
      const $link = $(e.currentTarget);
      const container = $($link.data("target-container"));
      const output = $link.data("output");
      this.modal = new UploaderModal({uploadScreen: this.uploadScreen, container, output});
      this.modal.show();
    });
  }

  installFileSelectedListener() {
    this.uploadScreen.on("change", "input[type=file]", e => {
      if (this.modal) {
        this.modal.fileSelected(e.currentTarget.files[0]);
      }
    });
  }

  installUploadProfileImageButtonListener() {
    this.uploadScreen.on('click', 'a#upload-image', e => {
      e.preventDefault();
      this.modal.uploadButtonClicked();
    });
  }

  installFormSubmitListener() {
    this.uploadScreen.on("submit", "form", e => {
      e.preventDefault();
      e.stopPropagation();
      this.modal.upload();
    });
  }

  installCloseModalButtonListener() {
    this.uploadScreen.on('click', '.close i', () => this.modal && this.modal.hide());
  }

  installEscapeKeyListener() {
    function keyHandler(e) {
      if (e.which === 27 && this.modal) { this.modal.hide(); }
    }

    const handler = keyHandler.bind(this);

    $(document).on('keydown', handler);
  }

  installDragDropListeners() {
    if (this.isDragDropSupported()) {
      this.uploadScreen
        .addClass("drag-drop-supported")
        .on("drag dragstart dragend dragover dragenter dragleave drop", ".upload-container", e => {
          e.preventDefault();
          e.stopPropagation();
        })
        .on('dragover dragenter', ".upload-container", e => {
          $(e.currentTarget).addClass("file-dragged");
        })
        .on('dragleave dragend drop', ".upload-container", e => {
          $(e.currentTarget).removeClass("file-dragged");
        })
        .on('drop', ".upload-container", e => {
          const droppedFile = e.originalEvent.dataTransfer.files[0];
          this.modal.fileDropped(droppedFile);
        });
    }
  }

  isDragDropSupported() {
    const div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }
}

export {Uploader};
