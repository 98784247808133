import pageRunner from './page_runner';

pageRunner.registerSelector(".credit-card-details", container => {
  const cardNumber = container.find(".card-number input");
  const form = $("form.stripe-form");
  const submitButton = form.find("input[type=submit]");
  const errorContainer = $(".credit-card-details .error");

  function configureStripe() {
    Stripe.setPublishableKey(container.data("key"));
  }

  function loadStripeJavascript() {
    const script = document.createElement("script");
    script.onload = configureStripe;
    script.src = "https://js.stripe.com/v2/";
    document.head.appendChild(script);
  }

  function rejectNonNumericCharacters(e) {
    if (!e.ctrlKey && !e.metaKey && !e.altKey && e.key && e.key.length === 1 && (e.shiftKey || e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  }

  function addSpacesIntoCardNumber() {
    const components = cardNumber.val()
      .replace(/ /g, "")
      .slice(0, 16)
      .match(/..?.?.?/g);

    const newValue = components === null ? "" : components.join(" ");

    cardNumber.val(newValue);
  }

  function validateCreditCardFields() {
    const $cardNumber = form.find("input[data-stripe=number]");
    const $expMonth = form.find("input[data-stripe=exp_month]");
    const $expYear = form.find("input[data-stripe=exp_year]");
    const $cvc = form.find("input[data-stripe=cvc]");

    if ($cardNumber.val().length !== 19) {
      $cardNumber.trigger("focus");
      return false;
    }

    const month = parseInt($expMonth.val());
    if (isNaN(month) || month < 1 || month > 12) {
      $expMonth.trigger("focus");
      return false;
    }

    if ($expYear.val().length !== 2) {
      $expYear.trigger("focus");
      return false;
    }

    if ($cvc.val().length < 3) {
      $cvc.trigger("focus");
      return false;
    }

    return true;
  }

  function submitCreditCardForm(e) {
    if (!container.is(":visible")) { return; }

    e.preventDefault();

    errorContainer.hide();

    if (!validateCreditCardFields()) {
      return;
    }

    submitButton.prop('disabled', true);

    Stripe.card.createToken(form, (_status, response) => {
      if (response.error) {
        errorContainer.text(response.error.message).show();
        submitButton.prop('disabled', false);
      }
      else {
        form.append($('<input type="hidden" name="stripe_token">').val(response.id));
        form.get(0).submit();
      }
    });
  }

  if (window.Stripe) {
    configureStripe()
  } else {
    loadStripeJavascript();
  }

  container.find("input").on("keydown", rejectNonNumericCharacters);
  cardNumber.on("input", addSpacesIntoCardNumber);
  form.on("submit", submitCreditCardForm);
});
