import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setting"
export default class extends Controller {
  static targets = ['submit', 'field'];

  connect() {
    this.submitTarget.disabled = true;
    this.initialValue = this.fieldTarget.value;
  }

  arm() {
    this.submitTarget.disabled = this.initialValue === this.fieldTarget.value;
  }
}
