import pageRunner from './page_runner';
import { debounce } from 'throttle-debounce';
import { Uploader } from './uploading';

/* search */
$(document).on("DOMContentLoaded", () => {
  let form = $(".organisation-search form.search-container");

  function sendForm() {
    let params = form.serialize(),
        ajaxURL = location.pathname + "?ajax=very&" + params,
        prettyURL = location.pathname + "?" + params;

    $(".results-pane").load(ajaxURL);
    history.pushState && history.pushState({url: prettyURL}, null, prettyURL);
  }

  form.on("change input propertychange", "input", debounce(250, sendForm));
});

/* edit */
pageRunner.registerSelector(".organisation-edit", page => new Uploader(page).start());

/* view (admin only) */
pageRunner.registerSelector(".organisation-view #organisation_administrator_notes", $notes => {
  $notes.css("height", "1px");
  const height = $notes[0].scrollHeight + 25;
  $notes.css("height", `${height}px`);

  $notes.one('click input', () => $notes.addClass("activated"));
});
