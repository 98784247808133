import pageRunner from './page_runner';
import PDFViewer from './pdf_viewer';
import {CategorySelector} from './category_selector';
import showConfirmRejectionDialog from './confirm_rejection_dialog';
import dialogConfirm from './dialogs';
import {get, post} from '@rails/request.js';

const legendKey = 'studentProfileDisplaySkillLegend';

function layout() {
  const jobs = $(".jobs");
  const currentParent = jobs.parent();
  const width = window.innerWidth || $(document).width();

  if (width < 1100 && currentParent.hasClass("medium-screen-jobs-container")) {
    jobs.detach().appendTo($(".small-screen-jobs-container"));
  }
  if (width >= 1100 && currentParent.hasClass("small-screen-jobs-container")) {
    jobs.detach().appendTo($(".medium-screen-jobs-container"));
  }
}

function skillLegendDisplay(on) {
  $(".skills-legend")[on ? 'show' : 'hide']();
  $("#open-skills-legend")[on ? 'hide' : 'show']();
  localStorage.setItem(legendKey, on);
}

function profileReviewDisplay(event) {
  const id = event.currentTarget.dataset.id;
  const content = document.getElementById(`profile-review-message-${id}`).innerHTML;

  dialogConfirm(content, {html: true, cancelText: null});
}

pageRunner.registerSelector(".student-view", page => {
  skillLegendDisplay(localStorage.getItem(legendKey) === 'true');
  $("#open-skills-legend").on('click', e => { e.preventDefault(); skillLegendDisplay(true); });
  $(".skills-legend .close").on('click', e => { e.preventDefault(); skillLegendDisplay(false); });

  $("#profile-reviews").on('click', 'tr.hover[data-id]', profileReviewDisplay);

  $(".record-student-interaction").on('click', e => {
    e.preventDefault();
    $(e.currentTarget).addClass("opened");
    $("#new-student-interaction .expansion").addClass("opened");
  });

  $("#new-student-interaction .ratings a").on('click', e => {
    e.preventDefault();
    $("#new-student-interaction .ratings a").removeClass('selected');
    const rating = $(e.currentTarget).addClass('selected').data('rating');
    $("#new-student-interaction input[name=rating]").val(rating);
    $("#new-student-interaction input[type=submit]").prop('disabled', false);
  });

  new PDFViewer(page).start();

  async function confirmRejection(data) {
    const name = $('h1.student-name').text();
    const studentId = page.data("token");
    const request = await get(`/jobs/${data.jobId}/prospects/0?student_id=${studentId}`).catch(() => ({ok: false}));

    if (request.ok) {
      const prospect = await request.json;
      if (prospect.prompt_for_rejection_note) {
        const {ok, note} = await showConfirmRejectionDialog(name);
        return ok ? {ok, data: {...data, note}} : {ok};
      }
      return {ok: true, data};
    } else {
      return {ok: false};
    }
  }

  function categorySelectorUpdate(data) {
    data.student_id = page.data("token");
    if (!data.reject) { data.no_rejection = "1"; } // don't reject if they haven't been in a visible category and rejection is not explicit

    const jobId = data.job_id;
    delete data.job_id;

    return post(`/jobs/${jobId}/prospects`, {body: data});
  }

  const selector = new CategorySelector().install(page);
  selector.confirmRejection = confirmRejection;
  selector.update = categorySelectorUpdate;

  $(window).on('resize.studentView', layout);

  layout();
});
