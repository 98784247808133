import {Controller} from "@hotwired/stimulus"
import {post, destroy} from '@rails/request.js';

// Connects to data-controller="saved-search"
export default class extends Controller {
  static values = {id: String, url: String};

  async stateChange(event) {
    const checkbox = event.currentTarget;

    if (checkbox.checked) {
      const response = await post(this.urlValue, {body: {c: this.getCriteria()}});
      const data = await response.json;
      console.log(data);
      if (data.id) {
        this.idValue = data.id;
      }
    }

    // This also handles the case where the checkbox is checked, then unchecked before the request to save the
    // saved search comes back.
    if (!checkbox.checked && this.idValue) {
      await destroy(this.urlValue, {body: {c: this.getCriteria()}});
      this.idValue = null;
    }
  }

  getCriteria() {
    return new URL(window.location).searchParams.get('c');
  }
}
