/*
 * When navigating away from a search page, store its scroll position. If the user navigates back to the page,
 * restore the scroll position.
 */

$(document).on("DOMContentLoaded", () => {
  const element = $(".search-results");

  if (element.length) {
    element.on('scroll', () => {
      window.sessionStorage.scrollPositionURL = location.href;
      window.sessionStorage.scrollPosition = element.scrollTop();
    });

    const scrollPosition = window.sessionStorage.scrollPosition;
    if (scrollPosition && window.sessionStorage.scrollPositionURL === location.href) {
      element.scrollTop(scrollPosition);
    } else {
      delete window.sessionStorage.scrollPositionURL;
      delete window.sessionStorage.scrollPosition;
    }
  }
});
