import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ['filterable'];
  static values = { filter: String };

  update(event) {
    if (event.currentTarget.selectedOptions) {
      this.filterValue = event.currentTarget.selectedOptions[0].value;
    }
    else {
      this.filterValue = event.currentTarget.value;
    }
  }

  filterValueChanged(value) {
    for (let element of this.filterableTargets) {
      element.hidden = value && element.dataset.filter !== value;
    }
  }
}
