import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="placement-cost"
export default class extends Controller {
  static targets = ['jtid', 'cost', 'description', 'container'];
  static values = {data: Object, jobType: Object};

  connect() {
    this.update();
  }

  changed(event) {
    this.update();
  }

  update() {
    this.jobTypeValue = this.hasJtidTarget ? this.dataValue[this.jtidTarget.value] : null;
  }

  jobTypeValueChanged(value) {
    this.containerTarget.style.display = typeof(value.cost) === 'undefined' ? 'none' : 'inherit';
    this.costTarget.innerText = value.cost;
    this.descriptionTarget.innerText = value.description || '';
  }
}
