import pageRunner from './page_runner';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import showConfirmRejectionDialog from './confirm_rejection_dialog';
import dialogConfirm from './dialogs';
import {post, put, destroy} from '@rails/request.js';

function makeAllHeadersTheSameHeight($container) {
  // One day we will be able to use a grid for this page.  But not today.  Thanks IE11.
  // For now we'll find the tallest header and make all the headers that tall.
  const headers = $container[0].querySelectorAll('header');
  if (!headers.length) { return; }

  for (const header of headers) { header.style.height = 'auto'; }
  const heights = Array.prototype.map.call(headers, o => o.clientHeight);
  const maxHeight = Math.max.apply(null, heights);
  const computedStyle = getComputedStyle(headers[0]);
  const verticalPadding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  const newHeight = (maxHeight - verticalPadding) + 'px';
  for (const header of headers) { header.style.height = newHeight; }
}

pageRunner.registerSelector('main.prospects-management, main.choices-management, main.waitlist-management', $container => {
  makeAllHeadersTheSameHeight($container);
  $(window).on('resize.prospectChoice', () => makeAllHeadersTheSameHeight($container));

  if (!$container.hasClass('editable')) { return; }

  $container.on("dblclick", "article", e => {
    const link = $(e.currentTarget).find("h3 a")[0];
    if (link) { link.click(); }
  });

  // Only used in the choices UI
  $container.on('click', '.trash', async e => {
    e.preventDefault();

    const $article = $(e.currentTarget).closest('article');
    const url = $article.data('url');

    const $section = $article.closest('section');
    const warning = $section.data('deletion-warning');

    if (warning) {
      const answer = await dialogConfirm(warning);
      if (!answer) { return; }
    }

    saving();

    const response = await destroy(url).catch(() => ({ok: false}));
    if (response.ok) { $article.remove(); }
    saved();
  });

  let $categories = $container.find(".categories");

  let drake = dragula({
    isContainer: function (el) {
      return el.classList.contains("category");
    },
    moves: function (el, source, handle, sibling) {
      return true; // elements are always draggable by default
    },
    accepts: function (el, target, source, sibling) {
      const sourceType = $(source).closest('section').data('type');
      const destType = $(target).closest('section').data('type');

      if (sourceType === 'apply' && destType === 'like') {
        return false;
      }

      return !sibling || $(sibling).index() > 1;
    },
    invalid: function (el, target) {
      return el.classList.contains("no-drag");
    },
    direction: 'vertical',             // Y axis is considered when determining where an element would be dropped
    copy: false,                       // elements are moved by default, not copied
    copySortSource: false,             // elements in copy-source containers can be reordered
    revertOnSpill: true,               // spilling will put the element back where it was dragged from, if this is true
    removeOnSpill: false,              // spilling will `.remove` the element, if this is true
    mirrorContainer: $categories[0],   // set the element that gets mirror elements appended
    ignoreInputTextSelection: true     // allows users to select input text, see details below
  });

  let olderSibling;

  drake.on("drag", (el, _source) => {
    olderSibling = $(el).prev();
  });

  drake.on("drop", async (el, target, source, _sibling) => {
    const $el = $(el);
    const url = $el.data("url");
    const localOlderSibling = olderSibling;
    const $category = $el.closest(".category");

    const sourceType = $(source).closest('section').data('type');
    const destType = $(target).closest('section').data('type');

    if (sourceType === 'like' && destType === 'apply') {
      const jobID = $el.data("job-id")
      location.href = `/jobs/${jobID}/applications/new`;
      return;
    }

    function reset() {
      $el.remove().insertAfter(localOlderSibling);
    }

    async function createRejectionRequest() {
      if (!url) {
        throw new Error("rejected candidates cannot be reordered");
      }

      const body = {};
      const prospect = await Promise.resolve($.getJSON(url));

      if (prospect.prompt_for_rejection_note) {
        const {ok, note} = await showConfirmRejectionDialog($el.data("name"));
        if (ok) {
          body.note = note;
        } else {
          return {ok};
        }
      }

      return destroy(url, {body});
    }

    function createMoveRequest() {
      // Only used for prospects
      const category_id = $category.data('id');
      // Only used for choices
      const choice_type = $category.data('type');

      const student_id = $el.data('student-id');
      const position = $el.index() - 2;

      const body = {position, category_id, choice_type};

      if (url) {
        return put(url, {body});
      } else {
        body.student_id = student_id;
        return post(location.href, {body, responseKind: 'json'});
      }
    }

    function createRequest() {
      const rejected = $category.hasClass('rejected');

      return rejected ? createRejectionRequest() : createMoveRequest();
    }

    saving();

    const response = await createRequest().catch(() => ({ok: false}));
    saved();

    if (response.ok) {
      const data = await response.json;
      $el.data('url', data.url || null);
    } else {
      reset();
    }
  });

  function saving() { $(".save-status").text("Saving..."); }
  function saved()  { $(".save-status").text("Saved"); updateCategoryCounts(); }

  function updateCategoryCounts() { $('.category').each(updateCategoryCount); };
  function updateCategoryCount() {
    const element = $(this);
    const count = element.find('.card').length;
    element.find('.category-count .count').html(count);
  };

  updateCategoryCounts();
});
