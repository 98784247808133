import {Controller} from "@hotwired/stimulus"
import * as ActiveStorage from '@rails/activestorage';

export default class extends Controller {
  static targets = ["progress", "progressWidth", "message"]

  connect() {
    ActiveStorage.start();
  }

  selected() {
    this.element.querySelector("form").dispatchEvent(new Event('submit'));
  }

  start() {
    this.progressTarget.style.display = 'flex'
  }

  progress(event) {
    const {progress} = event.detail
    this.progressWidthTarget.style.clipPath = `inset(0 ${100 - Math.round(progress)}% 0 0)`
    this.messageTarget.innerText = progress >= 100 ? 'Validating...' : 'Uploading...'
  }

  error(event) {
    const {error} = event.detail
    alert(error)
  }
}
