import {Controller} from "@hotwired/stimulus"
import {put} from '@rails/request.js';

export default class extends Controller {
  static targets = ['content', 'scroller'];
  static values = {url: String};

  connect() {
    this.contentTarget.addEventListener('scroll', () => this.onScroll());
    this.onScroll();
  }

  async agree() {
    const response = await put(this.urlValue);

    if (response.ok) {
      window.location.reload();
    }
    else {
      console.error(response);
      alert("There was an issue with your request. Please check your Internet connection and contact support if the problem persists.");
    }
  }

  scroll(event) {
    event.preventDefault();

    this.contentTarget.scrollTo({
      top: this.contentTarget.scrollTop + this.contentTarget.offsetHeight,
      behavior: 'smooth'
    });
  }

  onScroll() {
    const c = this.contentTarget;
    const atBottom = Math.ceil(c.scrollTop + c.offsetHeight) >= c.scrollHeight;

    this.scrollerTarget.hidden = atBottom;
  }
}
