import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="remote-form"
export default class extends Controller {
  static targets = ['errors'];

  connect() {
    if (this.hasErrorsTarget) this.errorsTarget.hidden = true;

    this.element.querySelectorAll("form").forEach(form => {
      form.addEventListener('submit', e => this.submit(e))

      form.querySelectorAll("input[type=submit], button").forEach(element => {
        delete element.dataset.disableWith;
      });
    });
  }

  async submit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const method = form.dataset.method || form.method || 'post';
    const params = {headers: {'Accept': 'application/json'}};

    if (method.toLowerCase() !== 'get') {
      params.body = new FormData(form);
    }

    const request = new FetchRequest(method, form.action, params);
    const response = await request.perform();

    if (response.ok) {
      window.location.reload();
    }
    else if (this.hasErrorsTarget) {
      this.errorsTarget.innerText = 'An error occurred submitting this form.';
      this.errorsTarget.hidden = false;

      const data = await response.json.catch(() => {});
      if (data && data.error) {
        this.errorsTarget.innerText = data.error;
      }
      else if (data && data.errors) {
        this.errorsTarget.innerText = data.errors.join(", ");
      }
    }
  }
}
