import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profile-reviews"
export default class extends Controller {
  static targets = ['response', 'message', 'displayResponses', 'approve', 'skip'];
  static values = {category: String};

  changeCategory(event) {
    this.categoryValue = event.currentTarget.value;
  }

  manageButtons() {
    this.approveTarget.disabled = this.skipTarget.disabled = this.messageTarget.value !== '';
  }

  toggleDialog(event) {
    event && event.preventDefault();
    this.displayResponsesTarget.checked = !this.displayResponsesTarget.checked;
  }

  fill(event) {
    event.preventDefault();

    let message = this.messageTarget.value;
    if (message) { message = message.trim().concat("\n"); }

    for (let element of this.responseTargets) {
      const checkbox = element.querySelector('input');
      if (checkbox.checked) {
        checkbox.checked = false;
        message = message.concat(`${element.dataset.response}\n`);
      }
    }

    this.messageTarget.value = message;
    this.toggleDialog();
    this.manageButtons();
    this.messageTarget.focus();
  }

  categoryValueChanged(value) {
    for (let element of this.responseTargets) {
      element.hidden = element.dataset.category !== value;
    }
  }
}
