export default function dialogConfirm(message, {okText, cancelText, html} = {}) {
  return new Promise(resolve => {
    const cancel = cancelText === null ? '' : `<a href="" class="button mode" data-action="cancel"></a>`;
    const html = `<div class="dialog-screen"><div class="dialog-box"><div class="content"></div><div class="actions"><a href="" class="button" data-action="ok"></a>${cancel}</div></div></div>`;

    const $dialog = $(html);
    const $content = $dialog.find('.content');

    if (html) {
      $content.html(message);
    } else {
      message.split("\n").forEach(line => $("<p>").text(line).appendTo($content));
    }

    $dialog.find('[data-action=ok]').text(okText || 'OK');
    if (cancelText !== null) {
      $dialog.find('[data-action=cancel]').text(cancelText || 'Cancel');
    }

    function keydownHandler(ee) {
      if (ee.which === 27) {
        hide();
        resolve(false);
      }
    }

    $(document).on('keydown', keydownHandler);

    $dialog.one('click', 'a', ee => {
      ee.preventDefault();
      hide();
      const $clicked = $(ee.currentTarget);
      const answer = ($clicked.data('action') === 'ok');
      resolve(answer);
    });

    function hide() {
      $(document).off('keydown', keydownHandler);
      $dialog.remove();
    }

    $('body').append($dialog);
  });
}
