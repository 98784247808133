import {Controller} from "@hotwired/stimulus"
import {get, put} from "@rails/request.js";
import dragula from 'dragula';

// Connects to data-controller="application-form"
export default class extends Controller {
  static targets = ['new', 'addButton', 'dragNotice', 'emptyNotice'];
  static values = {job: Number, editing: Boolean};

  connect() {
    this.editingValue = false;

    let drake = dragula([this.element], {
      isContainer: () => false,
      moves: el => !this.editingValue && el.dataset.controller === 'application-question',
      accepts: (_el, _target, _source, sibling) => !!sibling,
      invalid: () => false,
      revertOnSpill: true,
    });

    drake.on("drop", this.drop.bind(this));
  }

  async drop(el, _target, _source, _sibling) {
    const position = this.getPosition(el);
    const question = this.application.getControllerForElementAndIdentifier(el, 'application-question');
    const response = await put(question.url(), {body: {position}}).catch(() => ({ok: false}));

    if (!response.ok) {
      location.href = location.href;
    }
  }

  getPosition(el) {
    const siblings = el.parentNode.children;
    let position = 0;

    for (let n = 0; n < siblings.length; n++) {
      if (siblings[n].dataset.controller) {
        position++;
        if (siblings[n] === el) {return position;}
      }
    }
  }

  async add() {
    const response = await get(`/jobs/${this.jobValue}/application_questions/new`);
    const html = await response.html;
    this.newTarget.outerHTML = html;
    this.editingValue = true;
  }

  editingValueChanged(value) {
    this.addButtonTarget.style.display = value ? 'none' : null;
    this.dragNoticeTarget.style.visibility = value || this.questionCount() < 2 ? 'hidden' : 'visible';
    this.emptyNoticeTarget.style.display = value || this.questionCount() > 0 ? 'none' : null;
    this.element.classList.toggle('editable', !value);
  }

  questionCount() {
    return this.element.querySelectorAll('[data-controller="application-question"]').length;
  }
}
