import pageRunner from './page_runner';

$(document).on("DOMContentLoaded", function() {
  $(".agree-to-terms :checkbox").on("change", function(e) {
    $(e.target).closest('form').find(':submit').prop("disabled", !e.target.checked);
  });

  $("select.demographic").on("change", function(e) {
    let fieldName = $(e.currentTarget).data("field");
    let optionType = $(e.currentTarget).val();

    if (optionType === "user_specified") {
      $(".demographic." + fieldName).removeClass("predefined").addClass("user-specified");
      $("input." + fieldName).prop('disabled', false);
      $("input." + fieldName).prop('required', true);
      $("input." + fieldName).focus();
    } else {
      $(".demographic." + fieldName).removeClass("user-specified").addClass("predefined");
      $("input." + fieldName).prop('required', false);
      $("input." + fieldName).prop('disabled', true);
    }
  });
});

pageRunner.registerMainClass('student-registration', () => {
  const preferredNameElement = $('#student_preferred_name');

  function renderGreeting() {
    const pn = preferredNameElement.val();
    if (!pn) {
      $('.greeting').hide();
    } else {
      $('.greeting').show();
      $('.greeting-name').text(pn);
    }
  }

  let autofillEnabled = true;

  preferredNameElement.on('input', () => {
    autofillEnabled = false;
    renderGreeting();
  });

  $('#student_name').on('input', e => {
    if (autofillEnabled) {
      const name = e.target.value.split(' ')[0];
      preferredNameElement.val(name);
      renderGreeting();
    }
  });

  if (preferredNameElement.val() === '') {
    renderGreeting();
  }
  else {
    autofillEnabled = false;
  }
});
