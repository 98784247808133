import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide"
export default class extends Controller {
  static targets = ['element', 'antielement'];
  static values = { visible: Boolean };

  toggle(event) {
    event.preventDefault();
    this.visibleValue = !this.visibleValue;
  }

  show(event) {
    event.preventDefault();
    this.visibleValue = true;
  }

  hide(event) {
    event.preventDefault();
    this.visibleValue = false;
  }

  reflectChecked(event) {
    this.visibleValue = !!event.currentTarget.checked;
  }

  invertChecked(event) {
    this.visibleValue = !event.currentTarget.checked;
  }

  visibleValueChanged(value) {
    for (let element of this.elementTargets) {
      element.hidden = !value;
    }
    for (let element of this.antielementTargets) {
      element.hidden = !!value;
    }
  }
}
