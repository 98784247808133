import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="product-management"
export default class extends Controller {
  static targets = ['programmeOnly', 'expirable', 'feature', 'periodLength', 'productType', 'expiryPolicy'];

  connect() {
    this.setFieldVisibility();
  }

  setFieldVisibility() {
    if (this.hasProductTypeTarget) {
      const productType = this.productTypeTarget.value;
      const isProgramme = productType === 'programme';
      this.programmeOnlyTarget.hidden = !isProgramme;

      const expirable = ['programme', 'job_feature', 'organisation_feature'].includes(productType);
      this.expirableTarget.hidden = !expirable;

      const feature = ['job_feature', 'organisation_feature'].includes(productType);
      this.featureTarget.hidden = !feature;
    }

    if (this.hasExpiryPolicyTarget) {
      this.periodLengthTarget.style.display = this.expiryPolicyTarget.value === 'end_of_year' ? 'none' : 'block';
    }
  }
}
