import { debounce } from 'throttle-debounce';

$(document).on("DOMContentLoaded", function() {
  $(".skills-selection")
    .on("change", ".skill-categories input", function() {
      let categoryID = $(".skill-categories :checked").val();

      $(".skills-container [type=search]").val("");
      $(".skill-categories label").removeClass("dim");
      $(".skill-list").find("li.skill, li.category-header").hide()
        .filter((i, el) => $(el).data("category-id") === parseInt(categoryID))
        .show();
    })
    .on("change .skill-list input", function() {
      let list = $(".skills-selection .skill-display-list").empty();

      $(".skill-list :checked").closest(".skill-category")
        .each(function() {
          let li = $("<li>", { class: "skill-category" }).appendTo(list),
              ul = $("<ul>").appendTo(li);
          li.prepend($(this).find("h4").clone());
          $(this).find(":checked").closest("li").each(function() {
            $(this).find("label").clone()
              .appendTo(ul)
              .append("<i class=\"material-icons\" rel=\"close\">close</i>")
              .wrap("<li>")
          });
        });

      $("fieldset.skills, .skills-fields").removeClass("none some").addClass(list.children().length ? "some" : "none");
    })

  $(".skills-container [type=search]").on("input", debounce(100, function(e) {
    let query = $(e.target).val().toLocaleLowerCase().trim(),
        regexp,
        skills = $(".skills-container .skill-list").find("li.skill, li.category-header");

    if (query) {
      $(".skill-categories :checked").prop("checked", false);
      $(".skill-categories label").addClass("dim");

      regexp = new RegExp("^" + query.split(/\s+/).map(part => `(?=.*${escapeRegExp(part)})`).join() + ".*$", "i");
      skills.hide()
        .find("label span")
        .filter((i, el) => el.innerText.match(regexp))
        .parents("li").show()
        .parents("li").find("li.category-header").show();
    } else {
      $(".skill-categories label").removeClass("dim");
      skills.hide();
    }
  }));

  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }
});
