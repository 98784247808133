import { debounce } from 'throttle-debounce';
import pageRunner from './page_runner';
import showConfirmRejectionDialog from './confirm_rejection_dialog';
import dialogConfirm from './dialogs';
import {get, post} from '@rails/request.js';

function serializeForm() {
  let form = $(".students-search-container");

  let dictionary = {};
  form.serializeArray().forEach(({name, value}) => dictionary[name] = value);

  const yearMapping = {
    final: "F",
    penultimate: "P",
    graduated: "G",
    discontinued: "D"
  };

  const orderMapping = {
    created: "C",
    updated: "U",
    name: "N",
    skill: "S",
    interested: "E",
    impression: "M",
    team_impression: "T"
  };

  let value, criteria = [];

  value = yearMapping[dictionary.year];
  if (value) {
    criteria.push("Y" + value);
  }
  else if (dictionary.year === "specified") {
    criteria.push("Y" + form.find("#start_year").val() + form.find("#end_year").val());
  }

  let order = orderMapping[dictionary.order];
  if (order) { criteria.push("O" + order); }

  const pushIDCriteria = (name, letter) => {
    const ids = Object.keys(dictionary)
      .filter(field => field.substring(0, name.length + 1) == name + '[')
      .map(field => field.match(/\d+/)[0])
      .sort();

    if (ids.length) { criteria.push(letter + ids.join(".")); }
  };

  pushIDCriteria('institutions', 'I');
  pushIDCriteria('regions', 'R');
  pushIDCriteria('skills', 'S');
  pushIDCriteria('job_types', 'J');

  let states = Object.keys(dictionary).filter(field => field.substring(0, 7) == 'states[').map(field => field.match(/\[(.+)\]/)[1]);
  if (states.length) { criteria.push("s" + states.join(".")); }

  if (dictionary.student_interested) { criteria.push("i"); }
  if (dictionary.anonymise_profiles) { criteria.push("A"); }
  if (dictionary.bookmarked)         { criteria.push("b"); }

  let result = criteria.join("-");
  if (dictionary.q) { result += "~" + dictionary.q.trim(); }

  return result;
};

class StudentInfiniteScroller {
  constructor() {
    this.resetScrollState();
    this.loadCounter = 0;
  }

  resetScrollState() {
    const lastScrollPage = $(".search-results").data("last-scroll-page");

    this.scrollPage = lastScrollPage ? parseInt(lastScrollPage) : 1;
    this.scrollEnabled = true;
  }

  onLoad() {
    this.loadCounter++;

    const element = $("#students-search-results .search-results");

    if (element.length) {
      element.on("scroll", this.onScroll.bind(this));
      this.resetScrollState();
      this.onScroll();
    }
  }

  onResize() {
    const element = $("#students-search-results .search-results");

    if (element.length) {
      this.onScroll();
    }
  }

  setScrollPage(page) {
    this.scrollPage = page;
    $(".search-results").attr("data-last-scroll-page", this.scrollPage);
  }

  isOnStudentSearchPage() { return $("#students-search-results").length > 0; }

  makeQueryURL(params) {
    const sparams = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join("&");
    return sparams ? `${location.pathname}?${sparams}` : location.pathname;
  }

  makeNextPageRequest() {
    const params = {
      c: serializeForm(),
      search_token: $(".search-results").data("search-token"),
      page: this.scrollPage + 1,
      ajax: 'very',
    };

    $(".search-scroll-notifications .loading").show();

    const originalLoadCounter = this.loadCounter;
    const ajaxURL = this.makeQueryURL(params);
    const request = $.get(ajaxURL);

    return request
      .done((data, _, xhr) => {
        // If the search criteria have changed or the user's moved away from the page, do nothing.
        if (!this.isOnStudentSearchPage() || this.loadCounter !== originalLoadCounter) { return; }

        if (xhr.status === 200) {
          $(".search-scroll-notifications").remove();
          $(".search-results").append(data);
        }
        else {
          // We probably got a 204 back.
          $(".search-scroll-notifications .loading").hide();
        }
      })
      .fail(() => {
        if (this.isOnStudentSearchPage()) {
          $(".search-scroll-notifications .loading").hide();
          $(".search-scroll-notifications .error").show();
        }
      });
  }

  onScroll() {
    if (!this.scrollEnabled) { return; }

    const searchResults = $("#students-search-results .search-results");
    if (!searchResults.length) { return; }

    const current = searchResults.scrollTop();
    const trigger = searchResults.prop('scrollHeight') - (searchResults.height() * 2);

    if (current > trigger) {
      this.scrollEnabled = false;
      console.log("fetching page", this.scrollPage + 1);

      this.makeNextPageRequest().done((_a, _b, xhr) => {
        // We're given a 204 when there are no more students to return.
        if (this.isOnStudentSearchPage() && xhr.status === 200) {
          this.scrollEnabled = true;
          this.setScrollPage(this.scrollPage + 1);

          // The page may have changed since we started the process, run ourselves again
          // in the next tick as we might need to load in more data already.
          setTimeout(this.onScroll.bind(this), 0);
        }
      });
    }
  }
}

let lastCriteriaSent = null;

function pushChangedCriteria() {
  let form      = $("form.students-search-container"),
      prettyURL = location.pathname,
      ajaxURL   = location.pathname + "?ajax=very",
      criteria  = serializeForm();

  if (criteria === lastCriteriaSent) { return; }
  lastCriteriaSent = criteria;

  if (criteria) {
    let encoded = encodeURIComponent(criteria);
    prettyURL += "?c=" + encoded;
    ajaxURL   += "&c=" + encoded;
  }

  // If the user has just turned off anonymisation, we can get rid of the anonymisation notice and
  // prevent it from coming back this session.
  if (criteria.indexOf("A") === -1) {
    const notice = $(".search-results-container.with-notice");
    if (notice.length) {
      document.cookie = 'anonymous_ack=1';
      notice.removeClass('with-notice');
    }
  }

  $(".search-results").addClass("loading");

  const request = $.get(ajaxURL);

  request
    .always(() => $(".search-results").removeClass("loading"))
    .done((data, _, xhr) => {
      const savedSearchID = xhr.getResponseHeader('X-Saved-Search-ID');

      $("#students-search-results").html(data);
      $("#saved_search").prop('checked', !!savedSearchID);
      $(".saved-search-toggle").attr('data-saved-search-id-value', savedSearchID);
      scroller.onLoad();
    })
    .fail(() => lastCriteriaSent = null);

  history.pushState && request.done(() => history.pushState({url: prettyURL}, null, prettyURL));
}

pageRunner.registerSelector('form.students-search-container', form => {
  lastCriteriaSent = null;

  form
    .on("change input propertychange", "input:not(.excluded-from-criteria), select", debounce(250, pushChangedCriteria))
    .on("click", ".clear-skills", () => {
      $(".students-search-container aside .skill-display-list").empty();
      $("section.skills-container section.skill-list input:checked").prop('checked', false);
      $("fieldset.skills").removeClass("none some").addClass("none");
      pushChangedCriteria();
    })
    .on("click", "#any_institution_true", () => {
      $('.students-search-container .institutions input[type="checkbox"]').prop('checked', false);
    })
    .on("click", '.institutions input[type="checkbox"]', () => {
      let anyChecked = $('.students-search-container .institutions input:checked').length > 0;
      $("#any_institution_true").prop('checked', !anyChecked);
    })
    .on("change", "fieldset.job #job_id", e => {
      $("fieldset.job ul.checkboxes").toggleClass("hidden", !$(e.currentTarget).val());
    })
    .on('click', '.only-state', e => {
      $(e.currentTarget).closest('ul').find('input').prop('checked', false);
    })
    .on('click', '.status-prospect', () => {
      const link = $("a.prospects-link").prop("href");
      link && window.location.assign(link);
    })
    .on('click', '.status-choice, .status-my-rating, .status-other-ratings, .status-blurb', e => {
      const link = $(e.currentTarget).closest('.card').find('a.student-name').prop('href');
      link && window.location.assign(link);
    })
    .on('click', 'a.disqualify', async e => {
      e.preventDefault();

      const $article = $(e.currentTarget).closest('article');
      const name = $article.find('a.student-name').text();
      const studentId = $article.data('id');
      const jobId = form.data('job-id');

      const infoResponse = await get(`/jobs/${jobId}/prospects/0?student_id=${studentId}`).catch(() => ({ok: false}));

      if (infoResponse.ok) {
        const data = await infoResponse.json;

        const {ok, note} = data.prompt_for_rejection_note ?
          await showConfirmRejectionDialog(name) :
          await dialogConfirm("Are you sure you want to disqualify this candidate?").then(ok => ({ok}));

        if (ok) {
          const response = await post(`/jobs/${jobId}/prospects`, {body: {student_id: studentId, note}}).catch(() => ({ok: false}));

          if (response.ok) {
            $article.css('opacity', 0.2);
          }
        }
      }
    }).on("click", ".job-context-search", e => {
      e.preventDefault();
      const href = $(e.currentTarget).prop('href');
      const criteria = encodeURIComponent(serializeForm());
      window.location.assign(`${href}?c=${criteria}`);
    }).on('mouseover', '.help', e => {
      const $help = $(e.currentTarget);
      const skillsPresent = $(".students-search-container").serializeArray().some(({name}) => name.startsWith('skills['));
      const id = skillsPresent ? 'skills-help-box' : 'no-skills-help-box';
      const $helpBox = $("#" + id);
      const {left, top} = $help.position();
      const width = $helpBox.width();
      const height = $helpBox.height();
      $helpBox
        .prop("hidden", false)
        .css({opacity: 1, left: left - width, top: top + height / 2 + 4});
    }).on('mouseout', '.help', () => {
      $(".help-box").prop("hidden", true).css({opacity: 0});
    });
});

const scroller = new StudentInfiniteScroller();

$(document).on("DOMContentLoaded", scroller.onLoad.bind(scroller));
$(window).on("resize", scroller.onResize.bind(scroller));
