import {post, destroy} from '@rails/request.js';

$(document).on("DOMContentLoaded", () => {
  function installBookmarkHandler(container) {
    container.on("click", ".student-bookmark", async function(e) {
      e.preventDefault();

      const $el = $(e.currentTarget);
      const token = $el.data("token");
      const on = $el.hasClass("present");
      const url = `/candidates/${token}/bookmarks`;

      const response = on ? await destroy(url) : await post(url);

      if (response.ok) {
        $el.removeClass("present absent").addClass(on ? "absent" : "present").find('i').text(on ? 'star_outline' : 'star');
      }
    });
  }

  if ($(".student-search-results").length) {
    installBookmarkHandler($(".student-search-results"));
  }

  if ($(".student-view").length) {
    installBookmarkHandler($(".student-view"));
  }
});
