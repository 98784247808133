class PageRunner {
  constructor() {
    this.mainClassListeners = {};
    this.selectorListeners = [];
  }

  registerMainClass(className, callback) {
    if (this.mainClassListeners[className]) {
      this.mainClassListeners[className].push(callback);
    } else {
      this.mainClassListeners[className] = [callback];
    }
  }

  registerSelector(selector, callback) {
    this.selectorListeners.push({selector, callback});
  }

  load() {
    const mainElementsCollection = document.getElementsByTagName('main');
    const mainElements = Array.prototype.slice.call(mainElementsCollection);
    for (const main of mainElements) {
      const classList = Array.prototype.slice.call(main.classList);
      for (const className of classList) {
        const listeners = this.mainClassListeners[className];
        if (listeners) {
          const $main = $(main);
          for (const listener of listeners) {
            listener($main);
          }
        }
      }
    }

    for (const {selector, callback} of this.selectorListeners) {
      const page = $(selector);
      if (page.length) {
        callback(page);
      }
    }
  }
}

const pageRunner = new PageRunner();

window.addEventListener('DOMContentLoaded', () => pageRunner.load());

export default pageRunner;
