import pageRunner from './page_runner';

pageRunner.registerSelector('.purchases-list-container', () => {
  $("input#open").on('change', e => {
    e.currentTarget.form.submit();
  });
});

pageRunner.registerSelector(".new-purchase-container", () => {
  const paymentMethodRadio = $("#purchase_details_payment_method_credit_card");

  function updateCreditCardDetailsVisibility() {
    const isCreditCard = paymentMethodRadio.is(":checked");

    $(".credit-card-details").toggle(isCreditCard);
  }

  updateCreditCardDetailsVisibility();

  $(".payment-method input[type=radio]").change(updateCreditCardDetailsVisibility);
});
